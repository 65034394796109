<template>
  <div>
    <div v-if="page">
      <h2>{{ $t("vip-title") }}</h2>
      <div v-if="page.doc" class="row mb-3">
        <page-title :subheading="page.doc.content"></page-title>
      </div>
    </div>


    <div style="margin-left: 40%;margin-bottom: 20px;">
      <progress-bar :options="options" :value="data.percent" />
    </div>
    <div class="card">
      <div class="card-body" style="text-align: center;">
        <div style="text-align: center;">
          <div>
            <span style="font-size: xx-large;">Downline users</span>
          </div>
          <div v-for="u in data.perUser" :key="u.username" style="font-size: large;margin-bottom: 10px;">
            <div>
              <table style="border-spacing: 0px;">
                <tr>
                  <td style="margin:0px; padding: 0px; width: 30px;"><span class="pe-7s-user"
                      style="font-size: 26px;"></span>
                  </td>
                  <td style="margin:0px; padding: 0px; vertical-align: baseline; text-align: left;">
                    <span style="font-weight: 600;">{{ u.username }}</span><span style="margin-left: 5px;">{{
                      u.accountable }}
                      €</span><span style="margin-left: 3px; font-style: italic;">({{ u.total }} €)</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <progress-bar 
                      :options="o1" 
                      :value="Math.min(Math.round(((u.total / 60000) * 100 + Number.EPSILON) * 100) / 100, 100)" />
                  </td>
                </tr>
                <tr>
                  <td style=""></td>
                  <td style="padding-top: 10px;">
                    <div v-for="u2 in u.downlines" :key="u2.username" style="font-size: large;margin-bottom: 10px;">
                      <div>
                        <table style="border-spacing: 0px;">
                          <tr>
                            <td style="margin:0px; padding: 0px; width: 30px;"><span class="pe-7s-user"
                                style="font-size: 26px;"></span>
                            </td>
                            <td style="margin:0px; padding: 0px; vertical-align: baseline; text-align: left;">
                              <span style="font-weight: 600;">{{ u2.username }}</span><span style="margin-left: 5px;">{{
                                u2.accountable }}
                                €</span><span style="margin-left: 3px; font-style: italic;">({{ u2.total }} €)</span>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <progress-bar 
                              :options="o2" 
                              :value="Math.min(Math.round(((u2.total / 60000) * 100 + Number.EPSILON) * 100) / 100, 100)" />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>
  
<script>
import gql from "graphql-tag";
import * as moment from "moment";
import PageTitle from "@/components/common/PageTitle";
import ProgressBar from 'vuejs-progress-bar'
export default {
  name: "VipPage",
  components: {
    PageTitle,
    ProgressBar
  },
  data() {
    return {
      page: null,
      vip: null,
      data: null,
      counter: 0,
      width: 10,
      elem: null,
      main: null,
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 20,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#E9ECEF',
          inverted: false
        },
        layout: {
          height: 200,
          width: 200,
          verticalTextAlign: 120,
          horizontalTextAlign: 80,
          zeroOffset: 0,
          strokeWidth: 10,
          progressPadding: 0,
          type: 'circle'
        }
      },
      o1: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 16,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#E9ECEF',
          inverted: false
        },
        layout: {
          height: 20,
          width: 200,
          verticalTextAlign: 75,
          horizontalTextAlign: 3,
          zeroOffset: 0,
          strokeWidth: 10,
          progressPadding: 0,
          type: 'line'
        }
      },
      o2: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 16,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#f0c800',
          backgroundColor: '#E9ECEF',
          inverted: false
        },
        layout: {
          height: 20,
          width: 200,
          verticalTextAlign: 75,
          horizontalTextAlign: 3,
          zeroOffset: 0,
          strokeWidth: 10,
          progressPadding: 0,
          type: 'line'
        }
      }
    };
  },
  created() {
    this.fetchVip()

    this.$apollo.query({
      query: gql`query{doc(id:"vip"){content,title}}`
    })
      .then(({ data }) => {
        this.page = data;
      })
  },
  methods: {
    fetchVip() {
      this.axios.post(this.baseURL + '/admin-api/v0/u/vip?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token'))).then(response => {
        this.data = response.data;
        console.log(this.data);
      })
    },
    getImgs() {
      console.log(this.headingImages.splice(0, 3))
    }
  },
};
</script>

<style scoped>
.vuejs-progress-bar {
  display: block !important;
}
</style>